export default {
	ctn:{
		position: 'relative',
		display: 'flex',
		width: '100%',
		backgroundColor: 'rgba(255, 255, 255, 0.1)',
		flexDirection: 'row-reverse',

	},
	item:{
		padding: 10,
		color: 'white'
	},
	lastItem:{
		position: 'relative',
		minWidth: 30
	}
}