export default {
	ctn:{
		position: 'relative',
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 0,
		paddingBottom:0,
	},
	ctnContent:{
		maxWidth: 960,
		margin: 24
	}
}