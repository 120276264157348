import React from "react"

import st from './style'
import img from '../../../assets/images/quemfaz/monike.webp'

const Who = () => (
  
	<div style={st.ctn}>
		
		<div style={st.ctnContent}>
				<h1 style={st.h1}>A CERAMISTA</h1>
				<p>
					Monike Mar decidiu que se especializaria em bules de cerâmica desde as primeiras aulas. O encanto pelos processos e pelos resultados a fez seguir este caminho específico, porém vasto. O tempo do bule é diferente de outras peças e, por isso, Monike sente que o lugar onde mora é sua principal inspiração: na serra, em área rural, na cidade de Cunha (SP).
				</p>
				<p>
					Nascida em Niterói (RJ), Monike é formada em Comunicação Social - Jornalismo pela UFRJ, trabalhou com audiovisual por vários anos e começou a se interessar por artes visuais ao desenvolver um projeto pessoal com fotografias analógicas. A busca por um suporte a fez parar na cerâmica e desviar caminhos. Encantou-se pelo bule e decidiu trocar o escritório pelo ateliê.
				</p>
				<br />
				<img style={st.img} src={img} alt="Monike Mar" />
				<br/><br/>

				<h1 style={st.h1}>LOJA ONLINE</h1>
				
				<p>
					A cada ciclo de produção, surgem novos modelos e cores. Para ver as peças disponíveis, <a href="http://loja.santobule.com.br">acesse a loja virtual</a>. Enviamos para todo o Brasil.
				</p>
		</div>

	</div>
  
)

export default Who
