import React from "react"

import st from './style'

import img1 from '../../../assets/images/gallery/1.webp';
import img2 from '../../../assets/images/gallery/2.webp';
import img3 from '../../../assets/images/gallery/3.webp';
import img4 from '../../../assets/images/gallery/4.webp';
import img5 from '../../../assets/images/gallery/5.webp';
import img6 from '../../../assets/images/gallery/6.webp';
import img7 from '../../../assets/images/gallery/7.webp';
import img8 from '../../../assets/images/gallery/8.webp';

class Gallery extends React.Component{
	constructor(props){
		super(props);
		this.state={
			width:0
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.resize);
		this.resize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resize)
	}

	resize = () => {
		const width = window.innerWidth;

		this.setState({
			width,
		});
	}

	render(){
		return(
			<div style={st.ctn}>
				<div style={st.ctnContent}>
					<div style={st.ctnItem(this.state.width)}><img style={st.img(this.state.width)} src={img1} alt="Bule 1"/></div>
					<div style={st.ctnItem(this.state.width)}><img style={st.img(this.state.width)} src={img2} alt="Bule 1"/></div>
					<div style={st.ctnItem(this.state.width)}><img style={st.img(this.state.width)} src={img3} alt="Bule 1"/></div>
					<div style={st.ctnItem(this.state.width)}><img style={st.img(this.state.width)} src={img4} alt="Bule 1"/></div>
					<div style={st.ctnItem(this.state.width)}><img style={st.img(this.state.width)} src={img5} alt="Bule 1"/></div>
					<div style={st.ctnItem(this.state.width)}><img style={st.img(this.state.width)} src={img6} alt="Bule 1"/></div>
					<div style={st.ctnItem(this.state.width)}><img style={st.img(this.state.width)} src={img7} alt="Bule 1"/></div>
					<div style={st.ctnItem(this.state.width)}><img style={st.img(this.state.width)} src={img8} alt="Bule 1"/></div>
				</div>
			</div>
		)
	}
}



export default Gallery
