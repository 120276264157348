import React from "react"

import PropTypes from "prop-types"
import '../../../styles/css/style.css'

const MainPage = ({children}) => (
  <div>
  	{children}
  </div>
)

MainPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainPage
