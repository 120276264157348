import React from "react"

import st from './style'

const About = () => (
  
	<div style={st.ctn}>
		
		<h1 style={st.h1}>Contato</h1>
		<a style={st.a} href="mailto:santobuleceramicas@gmail.com">
			santobuleceramicas@gmail.com
		</a>
	</div>
  
)

export default About
