import React from "react"

import bg from '../../../assets/images/home04-20.jpg'
import st from './style'

import Header from '../../molecules/Header';

const Home = () => (
  
	<div style={st.ctn}>
		<img src={bg} style={st.bg} alt="bule sobre a mesa"/>
		<h1 style={st.h1}>SANTO BULE</h1>
		<br/>
		<div style={st.empty}></div>
		<div style={st.ctnHeader}>
			<Header/>
		</div>
	</div>
  
)

export default Home
