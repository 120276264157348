export default {
	ctn:{
		position: 'relative',
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		paddingBottom:0,
		marginBottom: 20
	},
	ctnContent:{
		maxWidth: 960,
		margin: 24,
	},
	img:{
		maxWidth: '100%'
	}
}