import React from "react"

import st from './style'

const About = () => (
  
	<div style={st.ctn}>
		
		<div style={st.ctnContent}>
			<h1 style={st.h1}>SANTO BULE</h1>
			<p>
				O trabalho da marca é autoral e experimental. Todos os bules são feitos à mão e criados em modelagem livre. Este tipo de modelagem garante uma personalidade própria a cada peça. Nenhuma é igual a outra. 
			</p>

			<p>
				O bule é um dos utilitários mais desafiadores. Para criar bules artísticos funcionais é preciso equilibrar técnica, beleza e criatividade. Além disso, cada parte é modelada separadamente. Base, tampa, bico, alça. Depois, é feito um trabalho minucioso para unir e harmonizar esses elementos soltos numa só peça.
			</p>

			<p>
				Podem ser usados para preparar e servir à mesa – chá, café, leite, por exemplo. O processo é inteiramente artesanal, feito em casa-ateliê, com uma estrutura minimalista. A produção é em pequena quantidade, elaborada com matérias-primas locais.
			</p>
		</div>

	</div>
  
)

export default About
