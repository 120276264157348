export default {
	ctn:{
		position: 'relative',
		minHeight: '100vh',
		maxHeight: '100vh',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	ctnContent:{
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	ctnItem:$width=>({
		flexBasis:  $width>400?'calc(100% / 4)':'calc(100% / 2)',
		height: $width>400?'calc(100% / 2)':'calc(100% / 4)',
		display: 'flex',
		position: 'relative'
	}),
	img:$width=>({
		position: 'relative',
		maxWidth: '100%',
		maxHeight: '100%',
		objectFit: 'cover'
	})
}