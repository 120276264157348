export default {
	ctn:{
		position: 'relative',
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-around'
	},
	h1:{
		color: 'white',
		position: 'relative',
		fontSize: 70,
		marginBottom: 0,
		paddingBottom: 0,
		fontFamily: 'AdventPro-Thin',
		textShadow: '1px 1px 5px #666'
	},
	empty:{
		position:'relative',
		color:'white'
	},
	bg:{
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		margin: 0,
		marginBottom: 0,
		paddingBottom: 0,
		zIndex:0
	},
	ctnHeader:{
		position: 'absolute',
		top:0,
		left:0,
		zIndex:1,
		width: '100vw'
	}
}