import React from "react"

import MainPage from '../components/templates/MainPage'
import MetaTags from '../components/MetaTags';

import Home from '../components/pages/Home'
import About from '../components/pages/About'
import Gallery from '../components/pages/Gallery'
import Who from '../components/pages/Who';
import Contact from '../components/pages/Contact'

const IndexPage = () => (
  <MainPage>
  	<MetaTags />
  	<Home />
  	<About />
  	<Gallery />
  	<Who />
  	<Contact />
  </MainPage>
)

export default IndexPage
