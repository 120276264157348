export default {
	ctn:{
		position: 'relative',
		minHeight: '300px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 0,
		paddingBottom:0,
		backgroundColor: 'rgb(194,168,125)' 
	},
	h1:{
		color: 'white',
	},
	a:{
		color: 'white',
		fontSize: 20,
		fontFamily: 'Ubuntu-Regular'
	}
}