import React from "react";

import PropTypes from "prop-types";

import Helmet from 'react-helmet';

import MetaImage from '../../assets/images/meta/meta-image.png';

class MetaTags extends React.Component{

	constructor(props) {
    super(props);
    this.state = {}
   }

	render(){
		return ( 
	    <Helmet>
		    <title>{this.props.title}</title>
		    <meta content={this.props.title} property='og:title'/>
				<meta content={this.props.title} name='twitter:title'/>
				<meta content={this.props.title} property='og:site_name'/>

				<meta content={this.props.name} name='author'/>
				<meta content={this.props.keywords} name='keywords'/>
				
				<meta content='http://santobule.com.br' name='identifier-url'/>
				<meta content={this.props.name} name='copyright'/>

				<meta content='http://santobule.com.br' name='twitter:url'/>
				<meta content='http://santobule.com.br' property='og:url'/>
				
				<meta content={this.props.description} name='description'/>
				<meta content={this.props.description} property='og:description'/>
				<meta content={this.props.description} name='twitter:description'/>
				
				<meta content={this.props.image} property='og:image'/>
				<meta content={this.props.image} name='twitter:image'/>
				<link href={this.props.href} rel='canonical'/>
				<meta content='pt_BR' property='og:locale'/>

				{
          <link rel="icon" type="image/png" href={MetaImage}/>
        }
			</Helmet>
	  )
	}
}

MetaTags.propTypes = {
	name: PropTypes.string,
  title: PropTypes.string, 
  description: PropTypes.string,
  href: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
};
MetaTags.defaultProps = {
	name: 'Santo Bule',
  title: "Santo Bule",
  description: 'Bules ',
  href: 'http://santobule.com.br',
  image: MetaImage,
  keywords: 'bules, ceramica, cerimônia do chá'
};

export default MetaTags
